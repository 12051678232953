import { customerStyle } from '@marmalade/ldi/lib/customer/theme';
import componentThemes from '@marmalade/themes/lib/component-base-themes';
import { baseThemeReset } from '@marmalade/themes/lib/theme-utilities';
import favicon from './favicon.png';
import logo from './logo.jpg';

// Colors
const colors = {
    colorPrimary: '#485B9C',
    colorSecondary: '#6193BF',
    colorDarkGrey: '#2B2D32',
    colorBlack: '#1D1D1D',
    colorWhite: '#FFF',
    colorError: '#C4690E',
};

// Headers
const { headerStyle } = componentThemes.header;
const headerTheme = {
    [headerStyle('color')]: colors.colorBlack,
    [headerStyle('colorIcon')]: colors.colorBlack,
    [headerStyle('colorSeperator')]: colors.colorBlack,
    [headerStyle('backgroundColor')]: colors.colorWhite,
    [headerStyle('logoTopBottomPadding')]: '5px',
};

// Footer
const { footerStyle } = componentThemes.footer;
const footerTheme = {
    [footerStyle('poweredBy')]: true,
    [footerStyle('color')]: colors.colorWhite,
    [footerStyle('backgroundColor')]: colors.colorSecondary,
};

// Panel
const { panelStyle, panelVariants } = componentThemes.panel;
const panelTheme = {
    [panelStyle('borderWidth')]: '1px',
    [panelStyle('backgroundColor')]: '#f2f7fc',
    [panelStyle('backgroundColor', panelVariants.aside)]: '#f2f7fc',
};

// Header Tags
const titleTheme = {
    titleColor: colors.colorBlack,
    titleFontWeight: 700,
};

// Progress Bar
const { arrowStyle, arrowVariants } = componentThemes.progressBar;
const progressBarTheme = {
    [arrowStyle('color')]: colors.colorDarkGrey,
    [arrowStyle('color', arrowVariants.active)]: colors.colorWhite,
    [arrowStyle('color', arrowVariants.complete)]: colors.colorWhite,
    [arrowStyle('backgroundColor')]: '#F1F1F1',
    [arrowStyle('backgroundColor', arrowVariants.active)]: colors.colorPrimary,
    [arrowStyle('backgroundColor', arrowVariants.complete)]: '#2F3B63',
};

// Buttons
const { buttonStyle, createButtonTheme } = componentThemes.button;
const buttonTheme = createButtonTheme({
    [buttonStyle('borderRadius')]: '4px',
    [buttonStyle('borderWidth')]: '1',
    [buttonStyle('backgroundColor')]: colors.colorWhite,
    [buttonStyle('color')]: colors.colorBlack,
    [buttonStyle('backgroundColor', 'primary')]: colors.colorPrimary,
    [buttonStyle('color', 'primary')]: colors.colorWhite,
    [buttonStyle('borderColor', 'primary')]: colors.colorPrimary,
    [buttonStyle('backgroundColor', 'tertiary')]: colors.colorWhite,
    [buttonStyle('backgroundColor', 'tertiary', 'hover')]: colors.colorPrimary,
    [buttonStyle('backgroundColor', 'selected')]: colors.colorPrimary,
    [buttonStyle('borderColor', 'selected')]: colors.colorPrimary,
    [buttonStyle('color', 'selected')]: colors.colorWhite,
    [buttonStyle('backgroundImage')]: 'none',
    [buttonStyle('fontWeight')]: '700',
});

// Labels
const { labelStyle, classicRadioStyle } = componentThemes.form;
const formTheme = {
    [labelStyle('fontWeight')]: 300,
    [classicRadioStyle('color')]: '#5c6770',
    [classicRadioStyle('color', 'selected')]: colors.colorSecondary,
    [classicRadioStyle('color', 'selected')]: '#219E3E',
    [classicRadioStyle('backgroundColor')]: '#ebebeb',
    [classicRadioStyle('backgroundColor', 'selected')]: colors.colorWhite,
};

// Errors
const { errorStyle } = componentThemes.form;
const errorTheme = {
    [errorStyle('color')]: colors.colorError,
};

// Help Buttons
const { helpInfoVariants, helpButtonStyle } = componentThemes.helpInfo;
const helpInfoTheme = {
    [helpButtonStyle('color', helpInfoVariants.help)]: colors.colorSecondary,
};

// Messages
const { messageVariants, messageStyle } = componentThemes.message;
const messageTheme = {
    [messageStyle('borderWidth')]: 2,
    [messageStyle('backgroundColor', messageVariants.help)]: colors.colorPrimary,
    [messageStyle('borderColor', messageVariants.help)]: colors.colorPrimary,
    [messageStyle('color', messageVariants.help)]: colors.colorBlack,
    [messageStyle('backgroundColor', messageVariants.error)]: '#FFFDF3',
    [messageStyle('borderColor', messageVariants.error)]: '#F7E700',
    [messageStyle('color', messageVariants.error)]: colors.colorError,
};

// Definition List
const { definitionListStyle } = componentThemes.definitionList;
const definitionListTheme = {
    [definitionListStyle('definitionBackgroundColor')]: colors.colorDarkGrey,
    [definitionListStyle('definitionColor')]: colors.colorWhite,
};

// Order Status
const { orderStatusStyle } = componentThemes.newBusinessOrderStatus;
const orderStatusTheme = {
    [orderStatusStyle('primaryBackgroundColor')]: colors.colorWhite,
    [orderStatusStyle('image')]: false,
    [orderStatusStyle('advertisment')]: false,
    [orderStatusStyle('definitionListOffWhite')]: true,
    [orderStatusStyle('copyColor')]: colors.colorDarkGrey,
};

// Border
const borderTheme = {
    borderRadius: '6px',
    borderColor: '#CCC',
    borderColorSuccess: '#CCC',
    borderColorDanger: colors.colorDanger,
};

// Other
const customerTheme = {
    [customerStyle('color_secondary')]: colors.colorSecondary,
    [customerStyle('Icon_color')]: colors.colorBlack,
    font: 'Arial, Helvetica, sans-serif',
    fontWeight: 400,
    fontBold: 600,
    colorBase: colors.colorBlack,
    favicon,
    logo,
    logo_link: '/ldi/new/quick-quote',
    A_textDecoration: 'none',
    A_color: colors.colorPrimary,
    A_color_hover: colors.colorPrimary,
    Page_backgroundColor: colors.colorWhite,
    telephone: '0330 343 8814',
    guid: 'FFF329F08AD54969B2CDB41FDFBD55B3',
    name: 'Hodgson',
    subdomain: 'hodgson',
};

export default {
    ...baseThemeReset,
    ...colors,
    ...buttonTheme,
    ...footerTheme,
    ...formTheme,
    ...headerTheme,
    ...helpInfoTheme,
    ...panelTheme,
    ...progressBarTheme,
    ...messageTheme,
    ...definitionListTheme,
    ...orderStatusTheme,
    ...titleTheme,
    ...errorTheme,
    ...borderTheme,
    ...customerTheme,
};
