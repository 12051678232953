import academy from './academy';
import advantage from './advantage';
import aplan from './aplan';
import endsleigh from './endsleigh';
import evergreen from './evergreen';
import hodgson from './hodgson';
import jjballantine from './jjballantine';
import myfirstuk from './myfirstuk';
import oakland from './oakland';
import onefamily from './onefamily';
import red from './red';
import riviera from './riviera';
import siainsurance from './siainsurance';
import swinton from './swinton';
import abbeyautoline from './abbeyautoline';

export const themes = [
    academy,
    advantage,
    aplan,
    endsleigh,
    hodgson,
    jjballantine,
    myfirstuk,
    oakland,
    onefamily,
    red,
    riviera,
    swinton,
    siainsurance,
    evergreen,
    abbeyautoline,
];
