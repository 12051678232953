import { MarmaladeThemeProvider } from '@marmalade/themes';
import * as Sentry from '@sentry/browser';
import 'airbnb-js-shims';
import React, { Fragment } from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { resizeEvent } from 'redux-window';
import { Helmet } from 'react-helmet';
import Router from './router';
import store from './store';
import { themes } from './third-party-themes';

const site = window.location.host.split('.')[0];
export const theme = themes.find(theme => site === theme.subdomain);

theme && theme.favicon && document.getElementById('faviconLink').setAttribute('href', theme.favicon);

// Add opening hours to marmalade theme
const activeTheme =
    theme === undefined
        ? {
              openingHours: true,
          }
        : theme;

Sentry.init({
    dsn: 'https://42fd93d504cf479aa4b1c536bac2459c@sentry.marmaladesoftware.co.uk/10',
    environment: process.env.REACT_APP_DEPLOY_ENVIRONMENT || 'dev',
    allowUrls: [/https?:\/\/.*\.wearemarmalade(-[a-z]+)?\.(co\.uk|vm)/],
});

window.dataLayer = window.dataLayer || [];

resizeEvent(store);
render(
    <Provider store={store}>
        <Fragment>
            <Helmet>
                <link
                    href={`https://fonts.googleapis.com/css?family=${(theme && theme.googleFont) ||
                        'Lato'}&display=swap`}
                    rel="stylesheet"
                />
                {theme && <meta name="robots" content="noindex" />}
            </Helmet>
            <MarmaladeThemeProvider subTheme={activeTheme}>
                <Router />
            </MarmaladeThemeProvider>
        </Fragment>
    </Provider>,
    document.getElementById('root')
);
