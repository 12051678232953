import * as authConstants from '@marmalade/account/lib/constants';
import accountReducer from '@marmalade/account/lib/reducer';
import { NAME as formName } from '@marmalade/form/lib/constants';
import formsReducer from '@marmalade/form/lib/reducer';
import { NAME as guidName, reducer as guidReducer } from '@marmalade/guid-details';
import { NAME as helpInfoName, reducer as helpInfoReducer } from '@marmalade/help-info';
import { NAME as internalName, reducer as internalReducer } from '@marmalade/is-internal';
import ldiReducers from '@marmalade/ldi/lib/all-reducers';
import { NAME as modalName, reducer as modalReducer } from '@marmalade/modal';
import * as queryConstants from '@marmalade/query/lib/constants';
import queryReducer from '@marmalade/query/lib/reducer';
import { loginEventReducer } from 'modules/datalayer-ga4';
import { combineReducers } from 'redux';
import reduxWindow from 'redux-window';

export default combineReducers({
    ...ldiReducers,
    [formName]: formsReducer,
    [authConstants.NAME]: accountReducer,
    [queryConstants.NAME]: queryReducer,
    [helpInfoName]: helpInfoReducer,
    [internalName]: internalReducer,
    [guidName]: guidReducer,
    [modalName]: modalReducer,
    loginEvent: loginEventReducer,
    reduxWindow,
});
