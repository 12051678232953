import Footer, { BrandedFooter } from '@marmalade/application-footer';
import StandardHeader from '@marmalade/headers/lib/standard';
import IntroducerWarning from '@marmalade/introducer-warning';
import { isInternal } from '@marmalade/is-internal';
import guid from '@marmalade/ldi/lib/guid';
import Page from '@marmalade/page';
import { Banner as TrustPilot } from '@marmalade/trust-pilot';
import glamorous, { withTheme } from 'glamorous';
import { bool, node, string } from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { theme } from '../index';

const FlexPage = glamorous(Page)({
    display: 'flex',
    flexFlow: 'column nowrap',
    minHeight: '100vh',
});

const ContentWrapper = glamorous.div({
    flexGrow: 1,
});

class ApplicationLayout extends Component {
    static propTypes = {
        guid: string,
        children: node.isRequired,
        internalUser: bool.isRequired,
    };

    static defaultProps = {
        guid: undefined,
    };

    render() {
        const adminIFrame = this.props.internalUser && window !== window.parent;
        return (
            <FlexPage>
                {!adminIFrame ? <StandardHeader /> : <div style={{ paddingTop: '10px' }}></div>}
                <IntroducerWarning guid={this.props.guid} />
                <ContentWrapper>{this.props.children}</ContentWrapper>
                {!theme && !adminIFrame ? <TrustPilot product="LDI" /> : null}
                {adminIFrame ? null : !theme ? <Footer product="ldi" /> : <BrandedFooter />}
            </FlexPage>
        );
    }
}

export default withTheme(
    connect(
        (state, ownProps) => (
            { guid: guid(state, ownProps.theme), internalUser: isInternal(state) }
        )
    )(ApplicationLayout));
