import { customerStyle } from '@marmalade/ldi/lib/customer/theme';
import componentThemes from '@marmalade/themes/lib/component-base-themes';
import { baseThemeReset } from '@marmalade/themes/lib/theme-utilities';
import favicon from './favicon.jpg';
import logo from './logo.png';

// Colors
const colors = {
    colorPrimary: '#94C11F',
    colorPrimaryDark: '#1F8E36',
    colorSecondary: '#D8D81E',
    colorLightGrey: '#F2F2F2',
    colorDarkGrey: '#666',
    colorBlack: '#333',
    colorWhite: '#FFF',
    colorError: '#C02B0A',
};

// Headers
const { headerStyle } = componentThemes.header;
const headerTheme = {
    [headerStyle('color')]: colors.colorBlack,
    [headerStyle('colorIcon')]: colors.colorBlack,
    [headerStyle('colorSeperator')]: colors.colorBlack,
    [headerStyle('backgroundColor')]: colors.colorWhite,
    [headerStyle('logoTopBottomPadding')]: '5px',
};

// Footer
const { footerStyle } = componentThemes.footer;
const footerTheme = {
    [footerStyle('poweredBy')]: 'dark',
    [footerStyle('color')]: colors.colorBlack,
    [footerStyle('backgroundColor')]: colors.colorLightGrey,
};

// Panel
const { panelStyle, panelVariants } = componentThemes.panel;
const panelTheme = {
    [panelStyle('borderWidth')]: '1px',
    [panelStyle('backgroundColor')]: colors.colorWhite,
    [panelStyle('backgroundColor', panelVariants.aside)]: colors.colorWhite,
};

// Header Tags
const titleTheme = {
    titleColor: colors.colorBlack,
    titleFontWeight: 700,
};

// Progress Bar
const { arrowStyle, arrowVariants } = componentThemes.progressBar;
const progressBarTheme = {
    [arrowStyle('color')]: colors.colorDarkGrey,
    [arrowStyle('color', arrowVariants.active)]: colors.colorWhite,
    [arrowStyle('color', arrowVariants.complete)]: colors.colorWhite,
    [arrowStyle('backgroundColor')]: colors.colorWhite,
    [arrowStyle('backgroundColor', arrowVariants.active)]: colors.colorPrimary,
    [arrowStyle('backgroundColor', arrowVariants.complete)]: colors.colorPrimaryDark,
};

// Buttons
const { buttonStyle, createButtonTheme } = componentThemes.button;
const buttonTheme = createButtonTheme({
    [buttonStyle('color')]: colors.colorBlack,
    [buttonStyle('backgroundColor')]: colors.colorWhite,
    [buttonStyle('borderWidth')]: '1',
    [buttonStyle('borderRadius')]: '2px',
    [buttonStyle('color', 'selected')]: colors.colorWhite,
    [buttonStyle('backgroundColor', 'selected')]: colors.colorPrimaryDark,
    [buttonStyle('borderColor', 'selected')]: colors.colorPrimaryDark,
    [buttonStyle('color', 'primary')]: colors.colorWhite,
    [buttonStyle('backgroundColor', 'primary')]: colors.colorPrimaryDark,
    [buttonStyle('borderColor', 'primary')]: colors.colorPrimaryDark,
    [buttonStyle('backgroundColor', 'tertiary')]: colors.colorWhite,
    [buttonStyle('backgroundColor', 'tertiary', 'hover')]: colors.colorPrimaryDark,
    [buttonStyle('backgroundImage')]: 'none',
    [buttonStyle('fontWeight')]: '500',
});

// Labels
const { labelStyle, classicRadioStyle } = componentThemes.form;
const formTheme = {
    [labelStyle('fontWeight')]: 300,
    [classicRadioStyle('color', 'selected')]: colors.colorSecondary,
};

// Errors
const { errorStyle } = componentThemes.form;
const errorTheme = {
    [errorStyle('color')]: colors.colorError,
};

// Help Buttons
const { helpInfoVariants, helpButtonStyle } = componentThemes.helpInfo;
const helpInfoTheme = {
    [helpButtonStyle('color', helpInfoVariants.help)]: colors.colorPrimary,
};

// Messages
const { messageVariants, messageStyle } = componentThemes.message;
const messageTheme = {
    [messageStyle('borderWidth')]: 2,
    [messageStyle('backgroundColor', messageVariants.help)]: colors.colorPrimary,
    [messageStyle('borderColor', messageVariants.help)]: colors.colorPrimary,
    [messageStyle('color', messageVariants.help)]: colors.colorWhite,
};

// Definition List
const { definitionListStyle } = componentThemes.definitionList;
const definitionListTheme = {
    [definitionListStyle('definitionBackgroundColor')]: colors.colorDarkGrey,
    [definitionListStyle('definitionColor')]: colors.colorWhite,
};

// Order Status
const { orderStatusStyle } = componentThemes.newBusinessOrderStatus;
const orderStatusTheme = {
    [orderStatusStyle('primaryBackgroundColor')]: colors.colorWhite,
    [orderStatusStyle('image')]: false,
    [orderStatusStyle('advertisment')]: false,
    [orderStatusStyle('definitionListOffWhite')]: true,
    [orderStatusStyle('copyColor')]: colors.colorDarkGrey,
};

// Border
const borderTheme = {
    borderRadius: '6px',
    borderColor: '#CCC',
    borderColorSuccess: '#CCC',
    borderColorDanger: colors.colorDanger,
};

// Other
const customerTheme = {
    [customerStyle('color_secondary')]: colors.colorSecondary,
    [customerStyle('Icon_color')]: colors.colorPrimary,
    font: '"Open Sans", sans-serif',
    fontWeight: 400,
    fontBold: 600,
    googleFont: 'Open+Sans',
    colorBase: colors.colorBlack,
    favicon,
    logo,
    logo_link: '/ldi/new/quick-quote',
    A_textDecoration: 'none',
    A_color: colors.colorPrimaryDark,
    A_color_hover: colors.colorPrimaryDark,
    Page_backgroundColor: colors.colorLightGrey,
    telephone: '020 3372 2160',
    guid: '7662F321FC9B4502A7DE14F6DB60A101',
    name: 'Evergreen Insurance Services',
    subdomain: 'evergreen',
};

export default {
    ...baseThemeReset,
    ...colors,
    ...buttonTheme,
    ...footerTheme,
    ...formTheme,
    ...headerTheme,
    ...helpInfoTheme,
    ...panelTheme,
    ...progressBarTheme,
    ...messageTheme,
    ...definitionListTheme,
    ...orderStatusTheme,
    ...titleTheme,
    ...errorTheme,
    ...borderTheme,
    ...customerTheme,
};
