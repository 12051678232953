import { policies as policiesSelector } from '@marmalade/ldi/lib/customer/selectors/customer';
import {
    getEmailHash,
    loginAttempts as loginAttemptsSelector,
    loginError as loginErrorSelector,
    loginResult as loginResultSelector,
} from '@marmalade/account/lib/selectors';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

window.dataLayer = window.dataLayer || [];

const defaultState = {
    loginState: 'idle',
};

export const loginEventReducer = (state = defaultState, action) => {
    switch (action.type) {
        case 'account/LOGIN_FULFILLED':
            return {
                ...state,
                loginState: 'awaiting policies',
            };

        case 'customer/FETCH_CUSTOMER_FULFILLED':
            if (state.loginState === 'awaiting policies') {
                return {
                    ...state,
                    loginState: 'ready',
                };
            }
            return state;

        case 'loginEvent/RESET':
            return defaultState;

        default:
            return state;
    }
};

const LoginEvent = () => {
    const policies = useSelector(policiesSelector);
    const activePolicyCount = policies.live.length;
    const policyCount = policies.live.length + policies.future.length + policies.history.length;
    const { loginState } = useSelector(state => state.loginEvent);
    const emailHash = useSelector(getEmailHash);
    const dispatch = useDispatch();

    const fireEvent = useRef();
    fireEvent.current = () => {
        const hasActivePolicies = activePolicyCount > 0;

        window.dataLayer.push({
            event: 'aa_login',
            aa: {
                user: {
                    user_id: emailHash,
                    policy_name: 'LDI',
                    policy_quantity: hasActivePolicies ? activePolicyCount : policyCount,
                    policy_status: hasActivePolicies ? 'active' : 'inactive',
                },
            },
        });

        dispatch({ type: 'loginEvent/RESET' });
    };

    useEffect(() => {
        if (loginState === 'ready') {
            fireEvent.current();
        }
    }, [loginState]);

    return null;
};

const PAGE_DATA_MAP = {
    '/ldi/renewal': {
        insurance_product: 'car',
        quote_engine_step_nr: '11',
        quote_engine_step_name: 'Renew your policy',
    },
    '/ldi/renewal/payment-success': {
        insurance_product: 'car',
        quote_engine_step_nr: '7',
        quote_engine_step_name: 'Payment success',
    },
    '/ldi/renewal/payment-failure': {
        insurance_product: 'car',
        quote_engine_step_nr: '8',
        quote_engine_step_name: 'Payment failure',
    },
};

const PageEvents = withRouter(({ location }) => {
    const emailHash = useSelector(getEmailHash);
    const loginAttempts = useSelector(loginAttemptsSelector);
    const loginResult = useSelector(loginResultSelector);
    const loginError = useSelector(loginErrorSelector);

    const fireEvent = useRef();
    fireEvent.current = () => {
        window.dataLayer.push({
            event: 'spa_page_view',
            aa: {
                page: {
                    brand_name: 'Marmalade',
                    page_path: location.pathname,
                    page_location: window.location.href,
                    ...PAGE_DATA_MAP[location.pathname],
                },
                user: {
                    user_id: emailHash,
                    login_data: {
                        login_attempts: loginAttempts,
                        login_result: loginResult,
                        error_message: loginError,
                    },
                },
            },
        });
    };

    useEffect(() => {
        fireEvent.current();
    }, [location.pathname, loginAttempts]);

    return null;
});

export const DataLayerGa4 = () => (
    <React.Fragment>
        <LoginEvent />
        <PageEvents />
    </React.Fragment>
);
