import { getValue } from '@marmalade/form/lib/selectors/field-info';
import { getRegYear, getVehicleProp } from '@marmalade/form/lib/selectors/vehicle-lookup';
import { stages } from '@marmalade/ldi/lib/new-business/constants';
import { getDlAttribution, getQuotes, quoteRef as getQuoteRef } from '@marmalade/ldi/lib/quote/selectors';
import { stages as renewal } from '@marmalade/ldi/lib/renewal/constants';
import { getQuery } from '@marmalade/query/lib/selectors';
import { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

window.dataLayer = window.dataLayer || [];

const newBusinessStages = stages.map(stage => stage.formId);
const renewalStages = renewal.map(stage => stage.formId);

const getCustomForLocation = location => {
    const isRenewal = location.pathname.startsWith('/ldi/renewal');
    return isRenewal
        ? {
              stages: {
                  vehicle: renewalStages[3],
                  cover: renewalStages[4],
                  contact: renewalStages[2],
                  occupation: renewalStages[1],
              },
              category: 'RENEWAL',
              name: 'LDI',
              sku: 'LDI-RENEW',
          }
        : {
              stages: {
                  vehicle: newBusinessStages[2],
                  cover: newBusinessStages[3],
                  contact: newBusinessStages[1],
                  occupation: newBusinessStages[1],
              },
              category: 'NEW',
              name: 'LDI',
              sku: 'LDI',
          };
};

const getFormValuesForDataLayer = (state, stages) => ({
    employmentStatus: getValue(state, stages.occupation, 'employmentStatus'),
    vehicleMake: getVehicleProp(state, stages.vehicle, 'manufacturer'),
    vehicleModel: getVehicleProp(state, stages.vehicle, 'shortModelName'),
    vehicleEngine: getVehicleProp(state, stages.vehicle, 'cc'),
    vehicleYear: getRegYear(state, stages.vehicle),
    insuranceGroup: getVehicleProp(state, stages.vehicle, 'abiGroup'),
    vehicleValue: getValue(state, stages.vehicle, 'value'),
    mainInsurer: getValue(state, stages.vehicle, 'mainInsurer'),
    daysOfCover: getValue(state, stages.cover, 'cover'),
    user_email: getValue(state, stages.contact, 'email'),
});

const getQuotedPrice = (state, daysOfCover) => {
    const quotes = getQuotes(state);
    if (quotes.length && typeof daysOfCover !== 'undefined') {
        const quote = quotes.find(quote => quote.cover === daysOfCover);
        return typeof quote !== 'undefined' && quote.premium;
    }
};

const stateToProps = (state, ownProps) => {
    const { category, name, sku, stages } = getCustomForLocation(ownProps.location);
    const formValues = getFormValuesForDataLayer(state, stages);
    const dlAttribution = getDlAttribution(state);
    const transactionId = getQuery(state, 'transactionId');
    const quotedPrice = getQuotedPrice(state, formValues.daysOfCover);
    const price = getQuery(state, 'price') || quotedPrice;
    const policyQuote = getQuery(state, 'policyQuote');
    const quoteRef = getQuoteRef(state);

    return {
        quoteRef,
        payload: {
            dlAttribution,
            transactionId,
            transactionTotal: price,
            transactionProducts: [
                {
                    sku,
                    name,
                    category,
                    price,
                    quantity: 1,
                },
            ],
            policyQuote,
            ...formValues,
        },
    };
};

const DataLayer = ({ location, payload, quoteRef }) => {
    // When the page url or dlAttribution change:
    // Fire dataLayer event with transaction and policyQuote details
    useEffect(() => {
        window.dataLayer.push(payload);
    }, [location.pathname, payload.dlAttribution]);

    // When a new full quote is received:
    // Fire ldiFullQuote dataLayer event
    const isOnMountRef = useRef(true);
    useEffect(() => {
        // Don't fire on mount if there is a quote as this will have been loaded
        // from sessionStorage
        if (isOnMountRef.current) {
            isOnMountRef.current = false;
            return;
        }

        if (quoteRef && location.pathname.startsWith('/ldi/new')) {
            window.dataLayer.push({
                event: 'ldiFullQuote',
            });
        }
    }, [quoteRef]);

    return null;
};

export default withRouter(connect(stateToProps)(DataLayer));
