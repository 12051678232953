import { customerStyle } from '@marmalade/ldi/lib/customer/theme';
import componentThemes from '@marmalade/themes/lib/component-base-themes';
import { baseThemeReset } from '@marmalade/themes/lib/theme-utilities';
import favicon from './favicon.ico';
import logo from './logo.png';

// Colors
const colors = {
    colorPrimary: '#E70103',
    colorSecondary: '#000',
    colorGrey: '#CFCFCF',
    colorLightGrey: '#f2f2f2',
    colorDarkGrey: '#5D5D5D',
    colorBlack: '#000',
    colorWhite: '#FFF',
    colorError: '#9F5206',
};

// Customer Base
const customerTheme = {
    [customerStyle('color_secondary')]: colors.colorSecondary,
    [customerStyle('Icon_color')]: colors.colorPrimary,
    font: 'sans-serif',
    colorBase: colors.colorBlack,
    favicon,
    logo,
    logo_link: '/ldi/new/quick-quote',
    A_textDecoration: 'underline',
    A_color: colors.colorPrimary,
    A_color_hover: colors.colorPrimary,
    Page_backgroundColor: colors.colorWhite,
    guid: '140EB0096AEB484D89B026ABED964071',
    name: 'RED Driving School',
    subdomain: 'red',
};

// Headers
const { headerStyle } = componentThemes.header;
const headerTheme = {
    [headerStyle('color')]: colors.colorBlack,
    [headerStyle('colorIcon')]: colors.colorBlack,
    [headerStyle('colorSeperator')]: colors.colorDarkGrey,
    [headerStyle('backgroundColor')]: colors.colorWhite,
    [headerStyle('logoTopBottomPadding')]: '3px',
};

// Footer
const { footerStyle } = componentThemes.footer;
const footerTheme = {
    [footerStyle('poweredBy')]: 'dark',
    [footerStyle('color')]: colors.colorBlack,
    [footerStyle('backgroundColor')]: colors.colorLightGrey,
};

// Panel
const { panelStyle, panelVariants } = componentThemes.panel;
const panelTheme = {
    [panelStyle('borderWidth')]: '0px',
    [panelStyle('backgroundColor')]: colors.colorLightGrey,
    [panelStyle('backgroundColor', panelVariants.aside)]: colors.colorGrey,
};

// Header Tags
const titleTheme = {};

// Progress Bar
const { arrowStyle, arrowVariants } = componentThemes.progressBar;
const progressBarTheme = {
    [arrowStyle('color')]: colors.colorWhite,
    [arrowStyle('color', arrowVariants.active)]: colors.colorWhite,
    [arrowStyle('color', arrowVariants.complete)]: colors.colorWhite,
    [arrowStyle('backgroundColor')]: colors.colorDarkGrey,
    [arrowStyle('backgroundColor', arrowVariants.active)]: colors.colorPrimary,
    [arrowStyle('backgroundColor', arrowVariants.complete)]: colors.colorSecondary,
};

// Buttons
const { buttonStyle, createButtonTheme } = componentThemes.button;
const buttonTheme = createButtonTheme({
    [buttonStyle('color')]: colors.colorWhite,
    [buttonStyle('borderRadius')]: '2px',
    [buttonStyle('borderWidth')]: '0',
    [buttonStyle('backgroundColor')]: colors.colorSecondary,
    [buttonStyle('backgroundColor', 'primary')]: colors.colorPrimary,
    [buttonStyle('backgroundColor', 'selected')]: colors.colorPrimary,
    [buttonStyle('backgroundImage')]: 'none',
});

// Labels
const { labelStyle, classicRadioStyle } = componentThemes.form;
const formTheme = {
    [labelStyle('fontWeight')]: 'bold',
    [classicRadioStyle('color', 'selected')]: colors.colorPrimary,
};

// Errors
const { errorStyle } = componentThemes.form;
const errorTheme = {
    [errorStyle('color')]: colors.colorError,
};

// Help Buttons
const { helpInfoVariants, helpButtonStyle } = componentThemes.helpInfo;
const helpInfoTheme = {
    [helpButtonStyle('color', helpInfoVariants.help)]: colors.colorSecondary,
};

// Help Messages
const { messageVariants, messageStyle } = componentThemes.message;
const messageTheme = {
    [messageStyle('borderWidth')]: 0,
    [messageStyle('backgroundColor', messageVariants.help)]: colors.colorPrimary,
    [messageStyle('color', messageVariants.help)]: colors.colorWhite,
};

// Definition List
const { definitionListStyle } = componentThemes.definitionList;
const definitionListTheme = {
    [definitionListStyle('definitionBackgroundColor')]: colors.colorDarkGrey,
    [definitionListStyle('definitionColor')]: colors.colorWhite,
};

// Order Status
const { orderStatusStyle } = componentThemes.newBusinessOrderStatus;
const orderStatusTheme = {
    [orderStatusStyle('primaryBackgroundColor')]: colors.colorWhite,
    [orderStatusStyle('image')]: false,
    [orderStatusStyle('advertisment')]: false,
    [orderStatusStyle('definitionListOffWhite')]: true,
    [orderStatusStyle('copyColor')]: colors.colorDarkGrey,
};

// Order matters...
export default {
    ...baseThemeReset,
    ...colors,
    ...customerTheme,
    ...headerTheme,
    ...footerTheme,
    ...buttonTheme,
    ...formTheme,
    ...helpInfoTheme,
    ...panelTheme,
    ...progressBarTheme,
    ...messageTheme,
    ...errorTheme,
    ...titleTheme,
    ...definitionListTheme,
    ...orderStatusTheme,
};
